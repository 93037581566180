import React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { mq } from '@/components/media/media'

type Props = {
  cssBraceWrapper: SerializedStyles
  image: string
}

export const BraceWrapper = (props: Props): JSX.Element => {
  const { cssBraceWrapper, image } = props

  return (
    <div css={[cssBraceWrapper, cssBraceWidth]}>
      <img src={image} alt="" css={cssBraceImage} />
    </div>
  )
}

const cssBraceWidth = css`
  width: calc(7.2vw + 2px);
  ${mq.pc} {
    width: calc(5vw + 20px);
    max-width: 96px;
  }
`

const cssBraceImage = css`
  width: 100%;
`
