import { css, keyframes, SerializedStyles } from '@emotion/react'

export const useAnimation = (
  vectors: { x: number; y: number }[]
): SerializedStyles => {
  const positions = ['top', 'right', 'bottom', 'left']
  const childList = [
    {
      position: ['0', 'auto', 'auto', '8px']
    },
    {
      position: ['0', '8px', 'auto', 'auto']
    },
    {
      position: ['auto', '8px', '0', 'auto']
    },
    {
      position: ['auto', 'auto', '0', '8px']
    }
  ]

  const braceAnimation = (x: number, y: number) => keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translate(${x}px, ${y}px);
  }
  10% {
    visibility: hidden;
    opacity: 0;
    transform: translate(${x}px, ${y}px);
  }
  30% {
    visibility: visible;
    opacity: 1;
    transform: translate(${x}px, ${y}px);
  }
  50% {
    visibility: visible;
    opacity: 1;
    transform: translate(${x}px, ${y}px);
  }
  100% {
    transform: translate(0, 0);
  }
`

  const cssBraceWrapper = css`
    position: absolute;
    ${childList.map((child, index) => {
      return css`
        &:nth-child(${index + 1}) {
          ${positions.map(
            (position, index) => `${position}: ${child.position[index]};`
          )}
          animation: ${braceAnimation(
            vectors[index].x,
            vectors[index].y
          )} 3s ease-out;
        }
      `
    })}
  `
  return cssBraceWrapper
}
