import React from 'react'
import { css, SerializedStyles } from '@emotion/react'

import color from '@/constants/color'

type Props = {
  position: SerializedStyles
}

export const ScrollIndicator = (props: Props): JSX.Element => {
  const { position } = props

  return <span css={[Indicator, position]}>SCROLL</span>
}

const cssArrowHead = css`
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: calc(100% + 6px);
  width: 52px;
  height: 1px;
  content: '';
  background-color: ${color.black1};
  transform: translateY(-50%);
`

const cssArrowAxis = css`
  position: absolute;
  top: 9px;
  right: auto;
  bottom: auto;
  left: calc(100% + 58px);
  width: 8.5px;
  height: 1px;
  content: '';
  background-color: ${color.black1};
  transform: rotate(-135deg);
  transform-origin: 0 0;
`

const Indicator = css`
  display: inline-block;
  font-size: 12px;
  letter-spacing: 1.2px;
  transform: rotate(90deg);
  &::before {
    ${cssArrowHead}
  }
  &::after {
    ${cssArrowAxis}
  }
`
