import React from 'react'
import { css } from '@emotion/react'

import { mq } from '@/components/media/media'
import { StyledLink } from '@/components/link'
import { cssShadowGradientButton } from '@/constants/linkStyles'

import { cssH2 } from '@/constants/utils'
import { cssCenter } from '@/constants/utils'
import { cssParagraph } from '@/constants/utils'

import arrow_white from '@/images/arrow_white.svg'

export const Contact = (): JSX.Element => {
  return (
    <section css={cssContactLayout}>
      <h2 css={cssH2}>
        <span>CONTACT</span>
        <br />
        <span>お問い合わせ</span>
      </h2>
      <p css={[cssParagraph, cssContactParagraph]}>
        新規事業開発やイノベーション創出、プロダクト開発に関するご相談など、お気軽にお問い合わせください。
      </p>
      <div css={cssCenter}>
        <StyledLink
          href="https://relic.co.jp/contact/"
          theme={cssShadowGradientButton}
          src={arrow_white}
        >
          お問い合わせはこちら
        </StyledLink>
      </div>
    </section>
  )
}

const cssContactLayout = css`
  padding: 60px 16px;
  ${mq.pc} {
    padding: 120px 16px;
  }
`

const cssContactParagraph = css`
  margin: 16px auto;
  ${mq.pc} {
    margin: 20px auto 30px;
  }
`
