import React from 'react'
import { css } from '@emotion/react'

import { mq } from '@/components/media/media'
import { StyledLink } from '@/components/link'

import { cssH2 } from '@/constants/utils'
import { cssCenter } from '@/constants/utils'
import { cssParagraph } from '@/constants/utils'
import { cssContainer } from '@/constants/utils'
import { cssGradientButton } from '@/constants/linkStyles'

import pc_recruit_bg from '@/images/pc_recruit_bg.jpg'
import sp_recruit_bg from '@/images/sp_recruit_bg.jpg'
import arrow_white from '@/images/arrow_white.svg'

export const Recruit = (): JSX.Element => {
  return (
    <section css={cssRecruitLayout}>
      <div css={cssRecruitWrapper}>
        <div css={cssContainer}>
          <h2 css={cssH2}>
            <span>RECRUIT</span>
            <br />
            <span>採用情報</span>
          </h2>
          <p css={[cssParagraph, cssRecruitParagraph]}>
            私たちと一緒に、世界に誇れる事業や
            <br />
            サービス/プロダクトを共創しませんか？
          </p>
          <div css={cssCenter}>
            <StyledLink
              href="https://relic.co.jp/recruit/"
              theme={cssGradientButton}
              src={arrow_white}
            >
              採用情報をみる
            </StyledLink>
          </div>
        </div>
      </div>
    </section>
  )
}

const cssRecruitParagraph = css`
  margin: 12px auto 16px;
  text-align: center;
  ${mq.pc} {
    margin: 10px auto 24px;
  }
`

const cssRecruitLayout = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 303px;
  padding: 24px 16px;
  background-image: url(${sp_recruit_bg});
  background-position: center;
  background-size: cover;
  ${mq.pc} {
    min-height: 440px;
    padding: 0 16px;
    background-image: none;
    background-image: url(${pc_recruit_bg});
  }
`

const cssRecruitWrapper = css`
  ${mq.pc} {
    position: relative;
    width: 100%;
    max-width: 1080px;
    height: 440px;
    margin: 0 auto;
  }
`
