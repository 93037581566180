import React from 'react'
import { css } from '@emotion/react'
import Slider from 'react-slick'

import { cssCenter } from '@/constants/utils'
import { cssH2 } from '@/constants/utils'
import { investmentList } from '@/constants/Investment'
import { InvestmentType } from '@/typedir/types'
import { cssShadowGradientButton } from '@/constants/linkStyles'
import { mq } from '@/components/media/media'
import more_btn from '@/images/more_btn.svg'
import arrow_white from '@/images/arrow_white.svg'
import settings2 from '@/constants/slickSettings2'

import { InvestmentListCard } from '@/components/investmentCard'
import { StyledLink } from '@/components/link'

export const Investments = (): JSX.Element => {
  const investment: InvestmentType[] = [...investmentList]

  return (
    <section css={cssInvestment}>
      <h2
        css={[
          cssH2,
          css`
            padding: 0 16px;
            margin-bottom: 92px;
          `
        ]}
      >
        <span>PORTFOLIO</span>
        <br />
        <span>投資先企業</span>
      </h2>
      <div className="investment-container">
        <Slider {...settings2}>
          {investment.map((investment, index) => {
            return (
              <InvestmentListCard
                btn={more_btn}
                key={index}
                investment={investment}
              />
            )
          })}
        </Slider>
        <div css={cssCenter}>
          <StyledLink
            href="http://relic.co.jp/services/open_innovation/portfolio"
            theme={cssShadowGradientButton}
            src={arrow_white}
          >
            投資先企業一覧
          </StyledLink>
        </div>
      </div>
    </section>
  )
}

const cssInvestment = css`
  padding-bottom: 60px;
  margin-top: -40px;
  ${mq.pc} {
    margin-top: -138px;
  }
`
