import React from 'react'
import { css } from '@emotion/react'
import Slider from 'react-slick'

import { mq } from '@/components/media/media'
import { ServiceCard } from '@/components/common/card'
import settings from '@/constants/slickSettings'

import { cssH2, GrayBG2 } from '@/constants/utils'

import gray_bg_02 from '@/images/gray_bg_02.jpg'
import { serviceList } from '@/constants/services'
import { MoreDetail } from '@/components/common/card'

export const OurService = (): JSX.Element => {
  return (
    <section css={cssOurServiceLayout}>
      <h2 css={[cssH2, cssTitleWrapper]}>
        <span>OUR SERVICE</span>
        <br />
        <span>グループ企業/サービス一覧</span>
      </h2>
      <div className="service-container">
        <div css={GrayBG2}>
          <img src={gray_bg_02} alt="" />
        </div>
        <Slider {...settings}>
          {serviceList.map((service, index) => {
            return (
              <ServiceCard
                MoreDetail={<MoreDetail />}
                key={index}
                service={service}
              />
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

const cssOurServiceLayout = css`
  position: relative;
  padding: 60px 0;
  ${mq.pc} {
    padding: 180px 0 0;
    margin-bottom: 180px;
  }
`

const cssTitleWrapper = css`
  padding: 0 16px;
  margin-bottom: 92px;
`
