import React from 'react'
import { css } from '@emotion/react'

import { mq } from '@/components/media/media'
import { StyledLink } from '@/components/link'
import { NewsList } from '@/components/common/news'

import color from '@/constants/color'
import { cssH2 } from '@/constants/utils'
import { cssNewsLink } from '@/constants/linkStyles'

import { NewsType } from '@/typedir/types'

import more_btn from '@/images/more_btn.svg'

type Props = {
  newsList: NewsType[]
}

export const News = (props: Props): JSX.Element => {
  const { newsList } = props

  return (
    <section css={cssNewsLayout}>
      <h2 css={[cssH2, cssNewsHeading]}>
        <span>NEWS</span>
        <span>お知らせ</span>
      </h2>
      <NewsList newsList={newsList} maxLength={3} />
      <StyledLink to="/news" src={more_btn} theme={cssNewsLink}>
        More detail
      </StyledLink>
    </section>
  )
}

const cssNewsLayout = css`
  position: relative;
  max-width: 1080px;
  padding: 0 16px;
  margin: 0 auto;
  ${mq.pc} {
    &::after {
      position: absolute;
      top: auto;
      bottom: 11px;
      width: calc(100% - 182px);
      height: 1px;
      content: '';
      background-color: ${color.gray1};
    }
  }
`

const cssNewsHeading = css`
  position: relative;
  margin: 16px auto;
  text-align: left;
  ${mq.pc} {
    display: flex;
    align-items: center;
  }
  span {
    background-color: ${color.white1};
    &:last-child {
      padding: 0 12px;
      font-weight: 400;
      ${mq.pc} {
        padding: 0 40px;
      }
    }
  }
  &::before {
    position: absolute;
    top: 50%;
    bottom: auto;
    z-index: -1;
    width: 100%;
    height: 1px;
    content: '';
    background-color: ${color.gray1};
  }
`
