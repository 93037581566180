import React, { useRef } from 'react'
import { css } from '@emotion/react'

import { mq } from '@/components/media/media'
import { Lead } from '@/components/top/kv/lead'

import { BraceWrapper } from './braceWrapper'
import { ScrollIndicator } from './scrollIndicator'

import { cssCenter } from '@/constants/utils'
import { GrayBG1 } from '@/constants/utils'

import gray_bg_01 from '@/images/gray_bg_01.jpg'
import Braces01 from '@/images/Braces01.svg'
import Braces02 from '@/images/Braces02.svg'
import Braces03 from '@/images/Braces03.svg'
import Braces_BL from '@/images/Braces_BL.svg'
import { useAnimation } from '@/hooks/../hooks/useAnimation'
import { useVector } from '@/hooks/../hooks/useVector'

export const KV = (): JSX.Element => {
  const box = useRef<HTMLDivElement>(null)
  const { vectors, x, y } = useVector(box)
  const cssBraceWrapper = useAnimation(vectors)

  const images = [Braces01, Braces02, Braces03, Braces_BL]

  return (
    <section css={KeyVisual}>
      <div css={GrayBG1}>
        <img src={gray_bg_01} alt="" />
      </div>
      <ScrollIndicator position={ScrollIndicatorPosition} />
      <div css={[cssCenter, cssKV]} ref={box}>
        {x > 0 && y > 0 && (
          <>
            {images.map((image, index) => {
              return (
                <BraceWrapper
                  key={index}
                  image={image}
                  cssBraceWrapper={cssBraceWrapper}
                />
              )
            })}
            <Lead />
          </>
        )}
      </div>
    </section>
  )
}

const ScrollIndicatorPosition = css`
  display: none;
  ${mq.pc} {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 24px;
    display: block;
  }
`

const KeyVisual = css`
  position: relative;
  height: calc(50vw + 160px);
  padding-top: calc(10vw + 60px);
  ${mq.pc} {
    height: calc(18vw + 468px);
    padding-top: 170px;
  }
`

const cssKV = css`
  position: relative;
  max-width: calc(65vw + 180px);
  height: calc(35vw + 95px);
  margin: 0 auto;
  ${mq.pc} {
    max-width: calc(50vw + 320px);
    height: calc(15vw + 200px);
  }
`
