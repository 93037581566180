import React from 'react'
import { css, SerializedStyles } from '@emotion/react'

import color from '@/constants/color'
import { mq } from '@/components/media/media'
import { InvestmentType } from '@/typedir/types'

import arrow from '@/images/arrow.svg'

export const InvestmentListCard = (props: {
  investment: InvestmentType
  theme?: SerializedStyles
  btn: string
}): JSX.Element => {
  const { investment, theme } = props

  return (
    <a
      css={[Card, theme]}
      href={investment.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div css={cssImageWrapper}>
        <img src={investment.image.url} alt="" />
      </div>
      <div css={DescriptionLayout}>
        <h3 css={HeadingThird}>{investment.company}</h3>
        <p css={Paragraph}>{investment.description}</p>
        <div css={MoreDetails}>
          <span>コーポレートサイトはこちら</span>
          <div>
            <img src={arrow} alt="外部ページへのリンク" />
          </div>
        </div>
      </div>
    </a>
  )
}

const Card = css`
  z-index: 100;
  display: block;
  width: calc(100vw - 32px);
  height: 394px;
  padding: 24px;
  margin: 16px 12px;
  border-radius: 10px;
  box-shadow: 0 3px 12px ${color.black3};
  ${mq.tablet} {
    width: 272px;
  }
  ${mq.pc} {
    width: 304px;
    margin: 24px 12px;
    background-color: ${color.white1};
    box-shadow: 0 3px 12px ${color.black3};
  }
`

const cssImageWrapper = css`
  max-width: 295px;
  margin: 0 auto;
`

const DescriptionLayout = css`
  display: flex;
  flex-direction: column;
  height: 206px;
  padding-top: 16px;
  ${mq.pc} {
    display: flex;
    flex-direction: column;
    height: 230px;
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 24px auto;
  }
`

const HeadingThird = css`
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
  color: ${color.black2};
  text-align: center;
  letter-spacing: 1px;
  ${mq.pc} {
    flex: 1;
    min-height: 0%;
    padding: 0 16px;
    margin: 0;
  }
`

const Paragraph = css`
  flex: 1 1 auto;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.7;
  color: ${color.black1};
  letter-spacing: 1.4px;
  ${mq.pc} {
    flex: 2;
    margin: 16px 0;
    font-weight: 400;
    line-height: 1.9;
  }
`

const MoreDetails = css`
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  div {
    display: flex;
    align-items: center;
    width: 14px;
    margin-left: 8px;
    &:first-child {
      width: 12px;
      margin-left: 2px;
    }
  }
`
