import React, { useEffect, useState } from 'react'

type VectorReturn = {
  vectors: {
    x: number
    y: number
  }[]
  x: number
  y: number
}

export const useVector = (
  box: React.RefObject<HTMLDivElement>
): VectorReturn => {
  const [xAxis, setXAxis] = useState<null | number>(null)
  const [yAxis, setYAxis] = useState<null | number>(null)
  useEffect(() => {
    const boxWidth = box.current ? box.current.getBoundingClientRect().width : 0
    const boxHeight = box.current
      ? box.current.getBoundingClientRect().height
      : 0
    const width = ((boxWidth - boxHeight * 0.6) / 2) * 0.93
    const height = boxHeight * 0.2
    setXAxis(width)
    setYAxis(height)
  }, []) // eslint-disable-line

  const x = xAxis ? xAxis : 0
  const y = yAxis ? yAxis : 0

  const vectors = [
    { x: x, y: y },
    { x: -x, y: y },
    { x: -x, y: -y },
    { x: x, y: -y }
  ]

  return { vectors, x, y }
}
