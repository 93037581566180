import React from 'react'
import arrow_03 from '@/images/arrow_03.svg'

type ArrowProps = {
  className?: string
  style?: React.CSSProperties
  onClick?: () => void
}

const NextArrow = (props: ArrowProps): JSX.Element => {
  const { className, style, onClick } = props
  return (
    <button
      type="button"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <img
        src={arrow_03}
        alt="次のサービスへ"
        className="arrow arrow--flipped"
      />
    </button>
  )
}

const PrevArrow = (props: ArrowProps): JSX.Element => {
  const { className, style, onClick } = props
  return (
    <button
      type="button"
      className={className}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={arrow_03} alt="前のサービスへ" className="arrow" />
    </button>
  )
}

const settings = {
  dots: true,
  arrows: true,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  swipeToSlide: true,
  centerMode: true,
  slidesToShow: 1,
  centerPadding: '25%',
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        centerPadding: '18%'
      }
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: '0'
      }
    }
  ]
}
export default settings
