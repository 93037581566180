import React from 'react'
import { css, keyframes } from '@emotion/react'

import { mq } from '@/components/media/media'

import color from '@/constants/color'

export const Lead = (): JSX.Element => {
  return (
    <div css={Wrapper}>
      <span css={[Common, Head, Animation]}>
        <span css={[Common, Accent]}>CO</span> INNOVATION PLATFORM
      </span>
      <span css={[Common, Foot, Animation]}>
        挑戦者と共創するインフラとなり
        <br />
        <span css={Common}>1,000</span>の事業と事業家を創出する
      </span>
    </div>
  )
}

const animation = keyframes`
  0% {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.98);
  }
  75% {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.98);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
`

const Wrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px;
`

const Common = css`
  font-family: 'Noto Sans JP', 'Yu Gothic Medium', '游ゴシック Medium', YuGothic,
    '游ゴシック体', 'ヒラギノ角ゴ Pro W3', 'メイリオ',
    'Hiragino Kaku Gothic Pro', Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
  font-size: calc(4vw + 2.5px);
  letter-spacing: calc(0.4vw + 0.25px);
  ${mq.pc} {
    font-size: calc((100vw - 768px) / 40 + 30px);
    letter-spacing: calc((100vw - 768px) / 200 + 6px);
  }
`

const Accent = css`
  position: relative;
  font-size: calc(4.3vw + 3px);
  letter-spacing: calc(0.43vw + 0.3px);
  ${mq.pc} {
    font-size: calc((100vw - 768px) / 50 + 30px);
    font-weight: 500;
    letter-spacing: calc((100vw - 768px) / 500 + 3px);
  }
  &::before {
    position: absolute;
    top: 49%;
    right: calc((1px + 1.1vw) * -1);
    bottom: auto;
    left: auto;
    width: calc(0.8px + 0.9vw);
    height: calc(0.4px + 0.45vw);
    content: '';
    background-color: ${color.blue3};
    transform: translateY(-50%);
    ${mq.pc} {
      right: calc((4px + 0.6vw) * -1);
      width: calc(4.5px + 0.4vw);
      height: calc(2.2px + 0.2vw);
    }
  }
`

const Head = css`
  font-size: calc(4.3vw + 3px);
  color: ${color.blue3};
  letter-spacing: calc(0.43vw + 0.3px);
  ${mq.pc} {
    font-size: calc((100vw - 768px) / 50 + 30px);
    font-weight: 500;
    letter-spacing: calc((100vw - 768px) / 500 + 3px);
  }
`

const Foot = css`
  margin-top: 1vw;
  font-size: calc(4vw + 2.5px);
  line-height: 1.7;
  letter-spacing: calc(0.4vw + 0.25px);
  ${mq.pc} {
    font-size: calc((100vw - 768px) / 40 + 30px);
    letter-spacing: calc(1vw / 200 + 6px);
  }
`

const Animation = css`
  text-align: center;
  animation: ${animation} 3s ease-out;
`
