import { css } from '@emotion/react'
import { mq } from '@/components/media/media'
import color from '@/constants/color'

export const cssH2 = css`
  color: ${color.black2};
  text-align: center;
  span {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 3px;
    ${mq.pc} {
      font-size: 64px;
      letter-spacing: 6.4px;
    }

    &:last-child {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 2.5px;
      ${mq.pc} {
        font-size: 28px;
        letter-spacing: 2.8px;
      }
    }
  }
`

export const cssCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const cssParagraph = css`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  color: ${color.black1};
  letter-spacing: 1px;
  ${mq.pc} {
    text-align: center;
  }
`

export const cssContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  background-color: ${color.white3};
  box-shadow: 0 3px 12px ${color.black4};

  ${mq.pc} {
    position: absolute;
    right: 0;
    left: auto;
    width: 460px;
    padding: 40px 70px;
    margin: 40px auto;
  }
`

export const GrayBG1 = css`
  position: absolute;
  top: 0;
  height: 164px;
  ${mq.pc} {
    height: 385px;
  }
  img {
    height: 100%;
  }
`

export const GrayBG2 = css`
  position: absolute;
  top: 78px;
  right: auto;
  bottom: auto;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 240px;
  ${mq.pc} {
    top: 228px;
    height: 480px;
  }
  img {
    height: 100%;
  }
`
