import React from 'react'
import { InvestmentType } from '@/typedir/types'
import logo_122 from '@/images/logo_122.png'
import logo_FOOLS from '@/images/logo_FOOLS.png'
import logo_ReBuild from '@/images/logo_Re-Build.png'
import logo_Spready from '@/images/logo_Spready.png'
import logo_Impact from '@/images/logo_impact.png'
import logo_time from '@/images/logo_time_leap.png'
import logo_famione from '@/images/logo_famione.png'
import logo_rang from '@/images/logo_langualess.png'
import logo_otta from '@/images/otta_logo.png'
import logo_ecbo from '@/images/logo_ecbo.png'
import logo_startup_technology from '@/images/logo_startup_technology.png'
import logo_w3_fortune from '@/images/logo_w3_fortune.png'

export const investmentList: InvestmentType[] = [
  {
    company: <span>W3 Fortune PTE. LTD.</span>,
    image: {
      url: logo_w3_fortune
    },
    description:
      'コレクタブルNFTの製造および販売を行うシンガポールのWeb3スタートアップ',
    url: 'https://www.w3fortune.com/'
  },
  {
    company: (
      <span>
        株式会社スタートアップ
        <br />
        テクノロジー
      </span>
    ),
    image: {
      url: logo_startup_technology
    },
    description:
      'スタートアップスタジオ事業とWebエンジニア育成に特化した「RUNTEQ」を運営するスタートアップ',
    url: 'https://startup-technology.com/'
  },
  {
    company: (
      <span>
        インパクトサークル
        <br />
        株式会社
      </span>
    ),
    image: {
      url: logo_Impact
    },
    description:
      '社会インパクトを可視化する投資プラットフォームを開発・運営するソーシャルFinTechスタートアップ',
    url: 'https://impact-circle.co.jp/'
  },
  {
    company: <span>株式会社122</span>,
    image: {
      url: logo_122
    },
    description:
      '胸のサイズから洋服を選ぶ女性ファッションブランド「HEART CLOSET」を展開するD2Cスタートアップ',
    url: 'https://heart-closet.com/'
  },
  {
    company: <span>タイムリープ株式会社</span>,
    image: {
      url: logo_time
    },
    description:
      '店舗の接客をインターネット上で行える次世代型の遠隔接客サービス「RURA」を提供するスタートアップ',
    url: 'https://timeleap.co.jp/'
  },
  {
    company: <span>株式会社ファミワン</span>,
    image: {
      url: logo_famione
    },
    description:
      'パーソナル妊活コンシェルジュサービス「famione(ファミワン)」を運営するフェムテックスタートアップ',
    url: 'https://famione.com/'
  },
  {
    company: <span>株式会社ラングレス</span>,
    image: {
      url: logo_rang
    },
    description:
      'ペットのヘルスケアをサポートするウェアラブルデバイス「inupathy」を展開するIoTスタートアップ',
    url: 'https://www.inupathy.com/langualess/'
  },
  {
    company: <span>株式会社FOOLS</span>,
    image: {
      url: logo_FOOLS
    },
    description:
      'グローバル展開する日本発ファッションスニーカーブランド「grounds」を手掛けるD2Cスタートアップ',
    url: 'https://www.fools-inc.com/'
  },
  {
    company: <span>株式会社otta</span>,
    image: {
      url: logo_otta
    },
    description:
      'タウンセキュリティ「スマート見守りプラットフォーム」を開発・運営する福岡発のIoTスタートアップ',
    url: 'https://otta.co.jp/'
  },
  {
    company: <span>株式会社Re:Build</span>,
    image: {
      url: logo_ReBuild
    },
    description:
      '沖縄でスタートアップスタジオ事業やWebエンジニア教育のための事業やSaaSを展開するスタートアップ',
    url: 'https://re-build.company/'
  },
  {
    company: <span>Spready株式会社</span>,
    image: {
      url: logo_Spready
    },
    description:
      'コラボレーションSNS「Spready」で事業開発に必要な出会いを創出・支援するHR Techスタートアップ',
    url: 'https://spready.co.jp/'
  },
  {
    company: <span>ecbo株式会社</span>,
    image: {
      url: logo_ecbo
    },
    description:
      '荷物一時預かりサービス「ecbo cloak」宅配物受け取りサービス「ecbo pickup」を運営するスタートアップ',
    url: 'https://ecbo.io/'
  }
]
